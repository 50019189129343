import React, { useEffect, useState } from 'react';
import styles from './AllCustomerOrders.module.css';
import DetailsComponent from './DetailsComponent';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const AllCustomerOrders = ({orders,setPopupData}) => {
  
//   const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5); // Adjust number of orders per page as needed

  const [selectedOrder, setSelectedOrder] = useState(null); // Track the selected order

  const [showdetail, setShowdetail] = useState(false);
//   useEffect(() => {
//     fetchOrders();
//   }, []);

  // Calculate the index of the first and last order for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Calculate total pages
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }
// Handle order selection
const handleViewDetails = (order) => {
  setSelectedOrder(order);  // Store the clicked order
  setShowdetail(true);      // Open the popup
};

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.orderList}>
      <h2 className={styles.titleh2}>Orders</h2>
      {currentOrders.length > 0 ? (
        <>
          <table className={styles.voucherTable}>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order,index) => (
                
                <tr key={order.PK}>
                  <td>{order.PK}</td>
                  <td>{order.customer?.name}</td>
                  <td>{order.amount}</td>
                  <td>{order.orderDate}</td>
                  <td>
                    <span className={`${order.paymentDetails.status === 'succeeded' ? styles.successclas : 'successclas'}`}>{order.paymentDetails?.status}</span></td>
                    <td>
                        <span className={styles.fulldetailbtn} onClick={() => handleViewDetails(order)}>View</span>
                    </td>
                  {/* {showdetail &&(
                    <td>
                    <DetailsComponent order={order} setShowdetail={setShowdetail}/>
                    </td>
                  )} */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className={styles.pagination}>
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`${styles.pageItem} ${currentPage === i + 1 ? styles.activePage : ''}`}
              >
                {i + 1}
              </button>
            ))}

{showdetail && selectedOrder && (
        <DetailsComponent order={selectedOrder} setShowdetail={setShowdetail} />
      )}
          </div>
        </>
      ) : (
        <p>No order found</p>
      )}


    </div>
  );
};

export default AllCustomerOrders;
