import { useState } from "react"
import ProfileTopStyles from "./cartTop.module.css"
import CartItems from "../CartItems/CartItems"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../../../fonts.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from "../../../features/toastSlice";
import { applyDiscount, clearVoucher } from "../../../features/cart/cartSlice";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export default function CartTop({setAlert}) {
    const navigate = useNavigate();
    const {cart:{cart, totalPrice, totalCount,finalTotalPrice, tax,discount, isPromoapplied, voucherPromoCode, totalBasePrice}} = useSelector((cart)=>cart)

    // console.log("totalPrice",totalPrice);

    const x = typeof(totalPrice);
    console.log("finalTotalPrice",finalTotalPrice);

    const y = typeof(tax);
    // console.log("y",y);

    const dispatch = useDispatch();

    const [voucherCode, setVoucherCode] = useState("");              // To store voucher code

    const [voucherValid, setVoucherValid] = useState(false);         // To track voucher validation

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [discountprice, setDiscount] = useState(0);                     // To store discount value


    const handleVoucherValidation = async (e) => {
        e.preventDefault();
        if(!voucherCode){
            dispatch(settoaststate(true));
            dispatch(setsize('sm'));
            dispatch(setvariant('error'));
            dispatch(setcolor('error'));
            dispatch(setmessage('Plase Enter Promo Code'));
            dispatch(setIcon('error'));
            dispatch(setuniqueId('003'))
            return
        }
        setOpenBackdrop(true)
        try {
            // const response = await fetch(`${apiBaseUrl}/items/validate-voucher/${voucherCode}`);

            const response = await fetch(`${apiBaseUrl}/items/validate-voucher`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    PK: voucherCode,
                }),
              });
            //   console.log("response:", response);
            const data = await response.json();
            // const data = await response.text();  // Temporarily use .text() instead of .json()
            // console.log("Response data:", data);
            // console.log("data voucher",data)
            if (response.ok) {
                setOpenBackdrop(false)
                setVoucherValid(true);

                if(!isPromoapplied){
                    // if(data.discountType === "OnlyItems")
                        // Dispatch the discount to be applied to the cart
                    dispatch(applyDiscount({ discount: data.discount, discountType:data.discountType,  voucherPromoCode: data.details.PK}));
                }
                setDiscount(data.discount);

                // dispatch(updatePromoStatefalse())

                dispatch(settoaststate(true));
                dispatch(setsize('sm'));
                dispatch(setvariant('success'));
                dispatch(setcolor('success'));
                dispatch(setmessage(`Voucher applied! Discount: ${data.discount}%`));
                dispatch(setIcon('success'));
                dispatch(setuniqueId('001'));
            } else {
                setOpenBackdrop(false)
                setVoucherValid(false);
                dispatch(settoaststate(true));
                dispatch(setsize('sm'));
                dispatch(setvariant('error'));
                dispatch(setcolor('error'));
                dispatch(setmessage('Invalid voucher code.'));
                dispatch(setIcon('error'));
                dispatch(setuniqueId('002'));
            }
        } catch (error) {
            setOpenBackdrop(false)
            console.error('Error validating voucher', error);
            dispatch(settoaststate(true));
            dispatch(setsize('sm'));
            dispatch(setvariant('error'));
            dispatch(setcolor('error'));
            dispatch(setmessage('An error occurred while validating the voucher.'));
            dispatch(setIcon('error'));
            dispatch(setuniqueId('003'));
            
        }
    };

   

    const handlenavigatecheckout = () => {
        // if(totalCount){}
        if(cart.length > 0){
            navigate("/shippingAddress")
        }else{
            dispatch(settoaststate(true));
            dispatch(setsize('sm'));
            dispatch(setvariant('soft'));
            dispatch(setcolor('warning'));
            dispatch(setmessage('You do not have any item in cart'));
            dispatch(setIcon('warning'));
            dispatch(setuniqueId('000'))
        }
    }

    const handlePromoCode = () => {
        dispatch(clearVoucher())
        setVoucherCode('')
    }

    return (
        <div className={ProfileTopStyles.main}>
            <Backdrop open={openBackdrop} style={{ zIndex: 1301 }}>
        <CircularProgress style={{ color: '#7ABDE3' }} />
      </Backdrop>
            <h1 className={ProfileTopStyles.mainheading}>Items In Your Cart</h1>
 
            <div className={ProfileTopStyles.bookContainer}>
                {/* left side */}
                <CartItems cart={cart} setAlert={setAlert} totalCount={totalCount} />
                {/* right side */}
 
                <div className={ProfileTopStyles.profileTopLeftContainer}>
                    <div className={ProfileTopStyles.profileTopLeft}>
                        <h3 className={ProfileTopStyles.leftTitle}>Cart Summary</h3>
                        <span className={ProfileTopStyles.labelText}>Items</span>
                        <div className={ProfileTopStyles.leftBtnsContainer}>
                            {totalCount}
                        </div>
 
                        <span className={ProfileTopStyles.labelText}>Postage and Packaging</span>
                        <div className={ProfileTopStyles.leftBtnsContainer}>
                            {cart.length > 0 ? `£ ${tax}` : "0"}
                        </div>
                        <span className={ProfileTopStyles.labelText}>{isPromoapplied ? 'Actual Total Price' : 'Total'}</span>
                        {<div className={ProfileTopStyles.leftBtnsContainer}>
                            {cart.length > 0 ? `£ ${Number(totalBasePrice + tax).toFixed(2)}` : "0"}
                            {/* ${(totalPrice + tax)} */}
                        </div>}

                        {isPromoapplied && 
                        <>
                        <span className={ProfileTopStyles.labelText}>Discounted Total Price</span>
                        {<div className={ProfileTopStyles.leftBtnsContainer}>
                            {Number(totalPrice + tax).toFixed(2)}
                            {/* ${(totalPrice + tax)} */}
                        </div>}
                        </>
                        }

                        {isPromoapplied && <span className={ProfileTopStyles.labelTextdiscount}>{`${discount}% Discount is applied to Total Price`}</span>}


                       {cart.length > 0 && <div>
                            <span className={ProfileTopStyles.promocodetitle}>Promo Code</span>
                            {!isPromoapplied && <div className={ProfileTopStyles.voucherInput}>
                                <input 
                                    className={ProfileTopStyles.voucherInputactiveinput}
                                    type="text" 
                                    placeholder="Enter Promo Code"
                                    value={voucherCode} 
                                    onChange={(e) => setVoucherCode(e.target.value)} 
                                />
                                <button className={ProfileTopStyles.claimbutton} onClick={handleVoucherValidation}>Claim</button>
                            </div>}
                            {
                                isPromoapplied && <div className={ProfileTopStyles.voucherInput}>
                                    <div className={ProfileTopStyles.inputandclear}>
                                        
                                <input
                                    className={ProfileTopStyles.voucherInputinactive}
                                    type="text" 
                                    placeholder="Enter voucher code" 
                                    value={voucherPromoCode} 
                                    // onChange={(e) => setVoucherCode(e.target.value)} 
                                    disabled
                                />
                                <span onClick={handlePromoCode} className={ProfileTopStyles.clearBtn}>&#10006;</span>
                                </div>
                                {/* <button disabled className={ProfileTopStyles.appliedbtn}>Applied</button> */}
                            </div>
                            }
                        </div>}
                        
                        {/* {isPromoapplied && (
                <div className={ProfileTopStyles.redeemContainer}>
                    <button 
                        className={ProfileTopStyles.redeemButton} 
                        onClick={redeemVoucher}
                    >
                        Redeem Voucher
                    </button>
                </div>
            )} */}
 
                    </div>
                    <div onClick={handlenavigatecheckout} className={ProfileTopStyles.leftBtnsContainer}>
                        <span className={ProfileTopStyles.checkoutText}>Checkout</span>
                    </div>
                </div>
 
 
            </div>
        </div>
    )
}
