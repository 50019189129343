import React, { useState, useEffect, useMemo } from "react";
import ShippingAddressStyle from "./ShippingAddress.module.css";
import Footer from "../../../Components/Footer/Footer";
import { ReactComponent as PlaceOrderbtn } from '../../../Assets/SVG/PlceOrderbtn.svg';
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import ModalDialog from '@mui/joy/ModalDialog';
import Box from '@mui/material/Box';
import orderconfirm from '../../../Assets/orderconfirm.png'
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from "../../../features/toastSlice";
import { clearcart } from "../../../features/cart/cartSlice";
import { useNavigate } from "react-router-dom";
import Checkout from "../../ComingSoon/ComingSoon";
import StripePayment from "../../../Components/StripePayment/StripePayment";
import { Backdrop, CircularProgress } from '@mui/material';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { post } from 'aws-amplify/api';

const ShippingAddress = () => {

  const { cart, totalPrice, totalCount, tax } = useSelector((state) => state.cart)

  const dispatch = useDispatch();
  const [openBackdrop, setOpenBackdrop] = useState(false);


  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');

  // const {gender, book, eyeColor, skincolor, hairstyle, haircolor, town, childname} = useSelector((characterinfo)=>characterinfo)
  const { gender, book, eyeColor, skincolor, hairstyle, haircolor, town, childname } = useSelector((state) => state.characterinfo);

  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({
    orderDetails: null,
    paymentDetails: null
  });


  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #d2d2d2',
      borderRadius: '10px',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '10px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7ABDE3' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#7ABDE3',
        color: 'white',
      },
    }),
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '25px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    CountryISO: '',
    AddressLine1: "",
    aptunit: "",
    City: "",
    ZipCode: "",
    Email: "",
    PhoneNumber: "",
    // CardNumber: "",
    // NameOnTheCard: "",
    // ExpiryMonth: "",
    // ExpiryYear: "",
    // SecurityCode: ""
  })

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    AddressLine1: "",
    City: "",
    ZipCode: "",
    Email: "",
    PhoneNumber: "",
    CardNumber: "",
    NameOnTheCard: "",
    ExpiryMonth: "",
    ExpiryYear: "",
    SecurityCode: ""
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      firstName: "",
      lastName: "",
      AddressLine1: "",
      City: "",
      ZipCode: "",
      Email: "",
      PhoneNumber: "",
      CardNumber: "",
      NameOnTheCard: "",
      ExpiryMonth: "",
      ExpiryYear: "",
      SecurityCode: ""
    })
    setFields((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (clientSecret === '') {
      setOpenBackdrop(true);
    } else {
      setOpenBackdrop(false);
    }
  }, [clientSecret]);


 



  // const [country, setCountry] = useState('');
  // const options = useMemo(() => countryList().getData(), []);

  // const handleCountryChange = (value) => {
  //   setCountry(value);
  //   handleInputChange({ target: { name: 'Country', value: value.label } });
  //   handleInputChange({ target: { name: 'CountryISO', value: value.value } });
  // };



  const handleclearData = () => {

    setFields({
      firstName: "",
      lastName: "",
      AddressLine1: "",
      aptunit: "",
      CountryISO:"",
      City: "",
      ZipCode: "",
      Email: "",
      PhoneNumber: "",
      CardNumber: "",
      NameOnTheCard: "",
      ExpiryMonth: "",
      ExpiryYear: "",
      SecurityCode: ""
    })

    setDetails({
      orderDetails: null,
      paymentDetails: null
    });
    dispatch(clearcart());

    navigate('/');
  }
  return (
    <>
      <Backdrop open={openBackdrop} style={{ zIndex: 1301 }}>
        <CircularProgress style={{ color: '#7ABDE3' }} />
      </Backdrop>

      <div className={ShippingAddressStyle.ContactUs}>
        <div className={ShippingAddressStyle.mainContainer}>
          <h1 className={ShippingAddressStyle.heading}>
            Shipping And Billing Details
          </h1>

          <div className={ShippingAddressStyle.HomeContactus}>
            <div className={ShippingAddressStyle.HomeContactustop}>
              <div className={ShippingAddressStyle.formData}>
                <div className={ShippingAddressStyle.row}>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      onChange={handleInputChange}
                      value={fields.firstName}
                    />
                    {errors.firstName && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      onChange={handleInputChange}
                      value={fields.lastName}
                    />
                    {errors.lastName && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>
                <div className={ShippingAddressStyle.row}>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="AddressLine1">Address Line 1*</label>
                    <input
                      type="text"
                      id="AddressLine1"
                      name="AddressLine1"
                      onChange={handleInputChange}
                      value={fields.AddressLine1}
                    />
                    {errors.AddressLine1 && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.AddressLine1}
                      </p>
                    )}
                  </div>
                </div>
                <div className={ShippingAddressStyle.row}>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="aptunit">Address Line 2 (optional)</label>
                    <input
                      type="text"
                      id="aptunit"
                      name="aptunit"
                      onChange={handleInputChange}
                      value={fields.aptunit}
                    />
                  </div>
                </div>
                {/* <div className={ShippingAddressStyle.row}>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="Country">Country</label>
                    <Select
                      options={options}
                      value={country}
                      onChange={handleCountryChange}
                      styles={customStyles}
                      id="Country"
                      name="Country"
                      menuPlacement="auto"
                    />
                    {errors.Country && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.Country}
                      </p>
                    )}
                  </div>
                </div> */}
                <div className={ShippingAddressStyle.row}>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="City">County/Town*</label>
                    <input
                      type="text"
                      id="City"
                      name="City"
                      onChange={handleInputChange}
                      value={fields.City}
                    />
                    {errors.City && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.City}
                      </p>
                    )}
                  </div>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="ZipCode">Postcode*</label>
                    <input
                      type="text"
                      id="ZipCode"
                      name="ZipCode"
                      onChange={handleInputChange}
                      value={fields.ZipCode}
                    />
                    {errors.ZipCode && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.ZipCode}
                      </p>
                    )}
                  </div>
                </div>
                <div className={ShippingAddressStyle.row}>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="Email">Email*</label>
                    <input
                      type="email"
                      id="Email"
                      name="Email"
                      onChange={handleInputChange}
                      value={fields.Email}
                    />
                    {errors.Email && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.Email}
                      </p>
                    )}
                  </div>
                  <div className={ShippingAddressStyle.field}>
                    <label htmlFor="PhoneNumber">Phone Number*</label>
                    <input
                      type="text"
                      id="PhoneNumber"
                      name="PhoneNumber"
                      onChange={handleInputChange}
                      value={fields.PhoneNumber}
                    />
                    {errors.PhoneNumber && (
                      <p className={ShippingAddressStyle.error}>
                        {errors.PhoneNumber}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className={ShippingAddressStyle.paymentDetails}>
                <StripePayment
                  childname={childname}
                  setDetails={setDetails}
                  tax={tax}
                  fields={fields}
                  totalCount={totalCount}
                  details={details}
                  cart={cart}
                  setErrors={setErrors}
                  totalPrice={totalPrice}
                  setClientSecret={setClientSecret}
                  clientSecret={clientSecret}
                  setOpenBackdrop={setOpenBackdrop}
                />
              </div>
            </div>
            <div className={ShippingAddressStyle.HomeContactusbottom}>
              <div className={ShippingAddressStyle.Footer}>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>

      {details.orderDetails && details.paymentDetails && (
        <Modal open={details.orderDetails} onClose={handleclearData}>
          <ModalDialog color="primary" layout="center" size="lg" variant="outlined">
            <img
              src={orderconfirm}
              alt="orderconfirm"
              style={{ width: '30%', margin: '0 auto', height: 'auto', display: 'block' }}
            />
            <ModalClose />
            <h1 style={{ textAlign: 'center', fontSize: '80%' }}>
              Your Order is Placed
            </h1>
            <Typography style={{ textAlign: 'center', fontSize: '80%' }}>
              You will be receiving a confirmation email with order details.
            </Typography>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
};

export default ShippingAddress;
