import React, { useEffect, useRef, useState } from 'react';
import navbarStyles from './Navbar.module.css';
import Logo from '../../Assets/Logo/footerLogo.webp';
import { ReactComponent as CartIcon } from '../../Assets/SVG/CartIcon.svg';
import '../../fonts.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CiLogin } from "react-icons/ci";
import { ImProfile } from "react-icons/im";
import { FaCircleUser } from "react-icons/fa6";
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import { Hub } from "aws-amplify/utils";
import { FaShoppingCart } from 'react-icons/fa';
import { Backdrop, CircularProgress } from '@mui/material';
import { clearcart } from '../../features/cart/cartSlice';

const Navbar = ({ fullName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOption, setShowOptions] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { totalCount } = useSelector(state => state.cart);

  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(localStorage.getItem("bookstorecurrentloginuser"));

  const userOptionsRef = useRef(null);
  const mobileMenuRef = useRef(null); // Add a ref for the mobile menu


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userOptionsRef.current && !userOptionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
      
      // Check if the click was outside the mobile menu and not on the hamburger menu
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        if (!event.target.closest(`.${navbarStyles.hamburgerMenu}`)) {
          setShowMobileMenu(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleOptions = () => {
    setShowOptions(!showOption);
  };

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userOptionsRef.current && !userOptionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        if (!event.target.closest(`.${navbarStyles.hamburgerMenu}`)) {
          setShowMobileMenu(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const authUser = await getCurrentUser();

        const userData = await fetchAuthSession();

        const payloaddata = userData?.tokens?.idToken?.payload;
      const groups = payloaddata["cognito:groups"] || [];
      if (groups.includes("Admin")){
        // console.log("groups",groups)
        setUser(null);
      }else{
        setUser(authUser.userId);
      }
        // setUser(authUser.userId);
      } catch (error) {
        setUser(null);
      }
    };

    checkUser();

    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        checkUser();
      }
      if (payload.event === 'signOut') {
        setUser(null);
      }
    });

  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setShowMobileMenu(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNavigate = (path) => {
    setActiveLink(path);
    navigate(path);
    setShowOptions(false);
    setShowMobileMenu(false);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleLogout = async () => {
    try {
      setShowOptions(false);
      setLoading(true);
      await signOut();
      localStorage.removeItem("bookstorecurrentusername");
      localStorage.removeItem("bookstorecurrentloginuser");
      localStorage.removeItem("CurrentFullName");
      await dispatch(clearcart());
      localStorage.removeItem("orderData");
      localStorage.removeItem("cart");
      

      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    } finally {
      setLoading(false);
      setShowOptions(false);
      setShowMobileMenu(false);
    }
  };

  const location = useLocation();
  const comingsoontheme = location.pathname === '/coming-soon/santas-Helper';


  return (
    <>
      <div style={{backgroundColor: `${comingsoontheme ? '#CF0F16' : '#7ABDE3'}`}} className={navbarStyles.navbar}>
        <div className={navbarStyles.navbartop}>
          <div className={navbarStyles.logowrapper}>
            <img onClick={() => handleNavigate('/')} src={Logo} alt='Logo' className={navbarStyles.Logo} />
          </div>
          {!isMobile && (
            <div className={navbarStyles.options}>
              <span className={`${navbarStyles.link} ${activeLink === '/' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/')}>HOME</span>
              <span className={`${navbarStyles.link} ${activeLink.startsWith('/our-books') ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/our-books')}>OUR BOOKS</span>
              <span className={`${navbarStyles.link} ${activeLink === '/our-story' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/our-story')}>OUR STORY</span>
              <span className={`${navbarStyles.link} ${activeLink === '/contactus' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/contactus')}>CONTACT US</span>
            </div>
          )}
          <div className={navbarStyles.cart}>
            {!isMobile && (
              <div className={navbarStyles.userOptions} ref={userOptionsRef}>

                {!user && (
                  <div className={navbarStyles.user} onClick={() => navigate('/login')}>
                    <FaCircleUser className={navbarStyles.userIcon} />
                  </div>
                )}
                {user && (
                  <>
                    <span style={{ fontSize: '14px', color: '#FFFFFF', fontFamily: 'Textfontfamily', cursor: 'pointer' }}>{fullName}</span>
                    <div className={navbarStyles.user} onClick={handleOptions}>
                      <FaCircleUser className={navbarStyles.userIcon} />
                    </div>
                  </>
                )}
                {showOption && user && (
                  <div className={navbarStyles.optionDiv}>
                    <div className={navbarStyles.optionItem} onClick={() => handleNavigate('/profile')}>
                      <ImProfile className={navbarStyles.optionItemIcon} />
                      <span>Profile</span>
                    </div>
                    <div className={navbarStyles.optionItem} onClick={handleLogout}>
                      <CiLogin className={navbarStyles.optionItemIcon} />
                      <span>Logout</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div style={{ position: 'relative' }}>
              <div className={navbarStyles.cartContainer} onClick={() => handleNavigate("/cart")}>
                <FaShoppingCart style={{ color: '#fff', fontSize: '24px', cursor: 'pointer' }} />
                {totalCount > 0 && <span className={navbarStyles.cartAmount}>{totalCount}</span>}
              </div>
            </div>
          </div>
          {isMobile && (
            <div className={navbarStyles.hamburgerMenu} onClick={toggleMobileMenu}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
        {showMobileMenu && (
          <div className={navbarStyles.mobileMenu} ref={mobileMenuRef}>
            <div className={navbarStyles.userOptions}>
              {user && <p>Hi, {fullName}</p>}
             {user && 
             <div onClick={() => navigate('/profile')}>
              <div className={navbarStyles.user} onClick={handleOptions}>
                  <FaCircleUser className={navbarStyles.userIconWhite} />
                </div>
                </div>}
            </div>
            <span className={`${activeLink === '/' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/')} style={{marginTop: '1rem'}}>HOME</span>
            <span className={`${navbarStyles.link} ${activeLink.startsWith('/our-books') ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/our-books')}>OUR BOOKS</span>
            <span className={`${activeLink === '/our-story' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/our-story')}>OUR STORY</span>
            <span className={`${activeLink === '/contactus' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/contactus')}>CONTACT US</span>
            {user ? <span className={`${activeLink === '/profile' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/profile')}>PROFILE</span>
            :
            <span className={`${activeLink === '/login' ? navbarStyles.active : ''}`} onClick={() => handleNavigate('/login')}>LOGIN</span> 
          }
          {user && <span  onClick={handleLogout}>LOGOUT</span>}
          </div>
        )}
      </div>
      <Backdrop open={loading} style={{ zIndex: 1300 }}>
        <CircularProgress style={{ color: '#7ABDE3' }} />
      </Backdrop>
    </>
  );
};

export default Navbar;
